import axios from "@axios";

export default {
  namespaced: true,
  state: {
    activeChat: {},
    contact: "",
    chatLog: "",
    contactsAndLastChat: [],
    chatMessage: "",
  },
  getters: {},
  mutations: {
    UPDATE_CONTACTS_AND_LAST_CHAT(state, val) {
      state.contactsAndLastChat = val;
    },
    UPDATE_CHAT_MESSAGE(state, val) {
      state.chatMessage = val;
    },
    UPDATE_ACTIVE_CHAT(state, val) {
      state.activeChat = val;
    },
    ADD_CHAT_MESSAGE(state, val) {
      // 更新 Contact 最後訊息
      const contact = state.contactsAndLastChat.filter(
        (c) => c.userId === val.contactId
      )[0];

      if (!contact) return;
      switch (val.message.messageType) {
        case "text":
          contact.messageType = "text";
          contact.messageText = val.message.messageText;
          break;
        case "image":
          contact.messageType = "image";
          break;
        case "video":
          contact.messageType = "video";
          break;
        case "audio":
          contact.messageType = "audio";
          break;
        case "file":
          contact.messageType = "file";
          break;
        case "location":
          contact.messageType = "location";
          break;
        case "sticker":
          contact.messageType = "sticker";
          break;
      }

      if (state.contactsAndLastChat.indexOf(contact) !== 0) {
        const filteredContacts = state.contactsAndLastChat.filter(
          (c) => c.userId !== val.contactId
        );

        filteredContacts.unshift(contact);
        state.contactsAndLastChat = filteredContacts;
      }

      if (
        state.activeChat.contact &&
        state.activeChat.contact.id === val.contactId
      ) {
        // 有開啟聯絡人
        // 更新已讀
        axios
          .post(`api/chat/contactread/${state.activeChat.contact.id}`)
          .then((response) => {})
          .catch((error) => reject(error));

        // 加入收到的訊息
        state.activeChat.chatLog.push(val.message);
      } else {
        contact.unReadCount += 1;
      }
    },
    UPDATE_CONTACT_LAST_MESSAGE(state, id, val) {
      // state.contactsAndLastChat.filters()
    },
    UPDATE_CONTACT_UNREAD_MESSAGE_COUNT(state, val) {
      let messages = state.contactsAndLastChat.filter(
        (c) => c.userId === val.userId
      );

      if (!messages.length) return;
      messages[0].unReadCount = val.count;
    },
    // 重設 state
    RESET_STATE(state) {
      state.activeChat = {};
      state.contact = "";
      state.chatLog = "";
      state.contactsAndLastChat = [];
      state.chatMessage = "";
    }
  },
  actions: {
    fetchContactsAndLastChat(context, contactId) {
      axios
        .get("api/chat/userlastchat")
        .then((response) => {
          if (contactId) {
            let userLastChat = response.data.filter(
              (c) => c.userId === contactId
            );

            if (!userLastChat.length) return;
            userLastChat[0].unReadCount = 0;
          }

          context.commit("UPDATE_CONTACTS_AND_LAST_CHAT", response.data);
        })
        .catch((error) => reject(error));
    },
    setContactAllMessageRead(context, userId) {
      context.commit("UPDATE_CONTACT_UNREAD_MESSAGE_COUNT", {
        userId,
        count: 0,
      });
    },
    resetState(context) {
      context.commit("RESET_STATE");      
    },
  },
};
