import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import store from "@/store";
import useJwt from "@/auth/jwt/useJwt";

let connection;

export const hubConnect = () => {
  connection = new HubConnectionBuilder()
    .withUrl(new URL("chatHub", process.env.VUE_APP_BASE_URL).href, {
      accessTokenFactory: () => useJwt.getToken(),
    })
    .withAutomaticReconnect({
      nextRetryDelayInMilliseconds: (retryContext) => {
        // 斷線 60 秒內，每 3 秒重連一次，超過 60 秒，每 10 秒重連一次
        if (retryContext.elapsedMilliseconds < 60000) {
          return 3000;
        } else {
          return 10000;
        }
      },
    })
    // .configureLogging(LogLevel.Information)
    .build();

  // 處理重新連線成功事件
  connection.onreconnected(function () {
    store.dispatch("verticalMenu/reload");
    store.dispatch("chat/fetchContactsAndLastChat");
  });

  let isBlinkTitle = false;
  connection.on("ReceiveChatMessage", function (chatMessage) {
    store.commit("chat/ADD_CHAT_MESSAGE", chatMessage);

    if (isBlinkTitle) return;
    blinkTitle();
  });

  connection.start();

  function blinkTitle() {
    if (!document.hasFocus()) {
      let showNewMessageTitle = false;
      const originalTitle = document.title;
      let intervalId = setInterval(function () {
        showNewMessageTitle = !showNewMessageTitle;
        if (showNewMessageTitle) {
          document.title = "新訊息 - " + originalTitle;
          return;
        }
        document.title = String.fromCharCode(8206);
      }, 1000);
      isBlinkTitle = true;

      function resetTitle() {
        clearInterval(intervalId);
        document.title = originalTitle;
        window.removeEventListener("focus", resetTitle);
        isBlinkTitle = false;
      }

      window.addEventListener("focus", resetTitle);
    }

    (function playAudio() {
      const audio = document.createElement("audio");
      audio.src =
        "https://vos.line-scdn.net/line-oa-crm-pc/0796a563bc00490b85150ec20a0c4e35.wav";
      audio.play();
    })();
  }
};

export const hubStop = () => {
  connection.stop();
};
