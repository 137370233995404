import { $themeConfig } from "@themeConfig";
import { nextTick } from "@vue/composition-api";

export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,
    isRouterAlive: true,
  },
  getters: {},
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val;
    },
    UPDATE_ROUTER_ALIVE(state) {
      state.isRouterAlive = false;
      nextTick(() => {
        state.isRouterAlive = true;
      });
    },
  },
  actions: {
    reload(context) {
      context.commit("UPDATE_ROUTER_ALIVE");
    },
  },
};
