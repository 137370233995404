export default [
    // *===============================================---*
  // *--------- CHAT  ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/chat",
    name: "apps-chat",
    component: () => import("@/views/apps/chat/Chat.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "chat-application",
    },
  },
  {
    path: "/apps/chat/:contactId",
    name: "apps-chat-contact",
    component: () => import("@/views/apps/chat/Chat.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "chat-application",
      navActiveLink: "apps-chat",
    },
  },
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/users/list",
    name: "apps-users-list",
    component: () => import("@/views/apps/user/users-list/UsersList.vue"),
  },
  // *===============================================---*
  // *--------- Setting ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/platform-setting",
    name: "platform-setting",
    component: () => import("@/views/pages/platform-setting/PlatformSetting.vue"),
  },
];
